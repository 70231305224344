<template>
    <div>
        <el-card class="box-card" shadow="never">

        
            <el-form
                :model="form"
                label-position="top"
                label-width="100px"
                ref="installment">

                <div class="text-center">
                    <h3>Create Service Plan </h3>
                </div>
                <el-row :gutter="20">
                    <el-col :xs="24" :sm="12" :md="8" :lg="8">
                        <el-form-item label="Patient" prop="patient_id">
                            <el-select
                                v-model="form.patient_id"
                                filterable
                                remote
                                reserve-keyword
                                placeholder="Please enter phone number"
                                :remote-method="searchGlobalPatient"
                                clearable
                                class="w-100"
                                required
                            >
                            <el-option
                                v-for="item in patients"
                                :key="item.id"
                                :label="item.fullname"
                                :value="item.id"
                            />
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :xs="24" :sm="12" :md="8" :lg="8">
                        <el-form-item label="Select Service Type" prop="service_type" :rules="[
                        {
                            required: true,
                            message: 'Please select service type',
                            trigger: 'blur',
                        }
                        ]">
                            <el-select
                                @change="serviceTypeChanged"
                                class="w-100"
                                v-model="form.service_type"
                                clearable
                                filterable
                                placeholder="Select Service Type"
                                required
                            >
                            <el-option
                                v-for="item in serviceTypes"
                                :key="item.label"
                                :label="item.label"
                                :value="item.value"
                            />
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :xs="24" :sm="12" :md="8" :lg="8" v-if="form.service_type">
                        <el-form-item label="Doctor" prop="doctor_id">
                            <el-select
                                v-model="form.doctor_id"
                                filterable
                                clearable
                                class="w-100"
                                required
                            >
                            <el-option
                                v-for="item in doctors"
                                :key="item.id"
                                :label="item.prefix+' '+item.username"
                                :value="item.id"
                            />
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :xs="24" :sm="12" :md="8" :lg="8" v-if="form.service_type == 'Procedure'">
                        <el-form-item label="Assistant" prop="assistant_id">
                            <el-select
                                v-model="form.assistant_id"
                                filterable
                                clearable
                                class="w-100"
                                required
                            >
                            <el-option
                                v-for="item in assistants"
                                :key="item.id"
                                :label="item.prefix+' '+item.username"
                                :value="item.id"
                            />
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :xs="24" :sm="12" :md="8" :lg="8" v-if="form.service_type == 'Procedure'">
                        <el-form-item label="Anaesthesia Type" prop="anaesthesia_type">
                            <el-select class="w-100" v-model="form.anaesthesia_type" placeholder="Select anaesthesia type">
                                <el-option label="Select One" value=""/>
                                <el-option label="Local" value="Local"/>
                                <el-option label="General" value="General"/>
                                <el-option label="Spinal" value="Spinal"/>
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :xs="24" :sm="12" :md="8" :lg="8">
                        <el-form-item label="Select Feet Type" prop="feet" :rules="[
                        {
                            required: true,
                            message: 'Please select feet type',
                            trigger: 'blur',
                        }
                        ]">
                            <el-select
                                class="w-100"
                                v-model="form.feet"
                                clearable
                                filterable
                                placeholder="Select Feet"
                                required
                            >
                            <el-option
                                v-for="item in feetLabels"
                                :key="item.label"
                                :label="item.label"
                                :value="item.value"
                            />
                            </el-select>
                        </el-form-item>
                    </el-col>
                   
                </el-row>

                <el-row>
                    <el-col :xs="24" :sm="24" :md="24" :lg="24">
                        <table align="left">
                            <thead>
                                <tr>
                                    <th>Schedule Date</th>
                                    <th>Schedule Time</th>
                                    <th>Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="(item, key) in form.schedules" :key="key">
                                    <td>
                                        <el-date-picker
                                            v-model="item.schedule_date"
                                            type="date"
                                            placeholder="Pick a day"
                                        />
                                    </td>
                                    <td>
                                        <el-time-picker
                                            v-model="item.schedule_time"
                                            arrow-control
                                            placeholder="Select time"
                                            />
                                    </td>
                                    <td>
                                        <el-button type="danger" circle @click="deleteRow(key)">
                                            <el-icon>
                                                <font-awesome-icon icon="fa fa-trash"/>
                                            </el-icon>
                                        </el-button>
                                    </td>
                                </tr>
                                <tr>
                                    <td colspan="3" align="right">
                                        <el-button type="success" @click="addMore">+ Add More</el-button>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </el-col>
                </el-row>

                <br/>
                <div class="text-center">
                    <el-button type="primary" @click="submitForm()" size="large">
                        Submit
                    </el-button>
                </div>
            </el-form>
        
        </el-card>
    </div>
</template>
<script>
import axios from 'axios';
import {
  Delete
} from '@element-plus/icons-vue'
    export default {
        name: 'ServicePlanCreate',
        data() {
            return {
                patients: [],
                doctors:[],
                assistants: [],
                form: {
                    "patient_id": "",
                    "doctor_id": "",
                    "service_type": "",
                    "feet": "",
                    "assistant_id": "",
                    "anaesthesia_type": "",
                    "schedules": [
                        {
                            "schedule_date": "",
                            "schedule_time": ""
                        }
                    ]
                   
                },
                serviceTypes: [
                    { label: "Casting", value: "Casting" },
                    { label: "Procedure", value: "Procedure" }
                ],
                feetLabels: [
                    { label: "Left", value: "Left" },
                    { label: "Right", value: "Right" },
                    { label: "Both", value: "Both" },
                ]
            }
        },
        components: {
            Delete
        },
        methods: {
            serviceTypeChanged() {
                if(this.form.service_type == 'Procedure') {
                    this.getSurgeons();
                    this.getAssistants();
                } else {
                    this.getDoctors();
                }
            },
            addMore() {
                this.form.schedules.push({
                            "schedule_date": "",
                            "schedule_time": ""
                        });
            },
            deleteRow(index) {
                this.form.schedules.splice(index, 1);
            },
            searchGlobalPatient(value) {
                if (value) {
                    axios
                    .get(`/api/v1/patient/subordinate/list?term=${value}`)
                    .then((response) => {
                        this.patients = response.data.data;
                    
                        if(this.patient_id) {
                            this.patientDetails();
                        }
                    });
                }
            },
            getDoctors() {
                axios.get(`/api/v2/doctor/center-wise/list?appointment=yes`)
                    .then(response => {
                        this.doctors = response.data.data;
                    })
            },
            getSurgeons() {
                axios.get(`/api/v2/doctor/surgeon/list`)
                    .then(response => {
                        this.doctors = response.data.data;
                    })
            },
            getAssistants() {
                axios.get(`/api/v2/doctor/center-wise/list?sub_type=physio_assistant&appointment=yes`)
                    .then(response => {
                        this.assistants = response.data.data;
                    })
            },
            submitForm() {
                let url = `/api/v2/zcf-service/plans`;
                axios
                .post(url, this.form)
                .then(res => {
                   
                    if (res.data.status_code != 200) {
                        this.$notify({
                        title: 'Failed',
                        message: res.data.message,
                        type: 'error',
                        duration: 2000,
                        });
                    } else {
                            this.$router.push('/zcf/service/plans');
                            this.$notify({
                            title: 'Success',
                            message: "Successfully submitted",
                            type: 'success',
                            duration: 2000,
                        });
                    }
                    
                })
            },

        }
    }
</script>

<style lang="scss" scoped>
table {
  font-family: Arial, Helvetica, sans-serif;
  border-collapse: collapse;
  width: 60%;
  th, td {
    border: 1px solid #ddd;
    padding: 8px 12px;
  }
}
</style>